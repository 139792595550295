
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faPlay, faPause, faTimes } from '@fortawesome/free-solid-svg-icons';
    import moment from 'moment';
    import { centerObject, zoomCameraToFitObject, THREE } from '@poplar-studio/model-viewer';
    import { BCol, BRow } from 'bootstrap-vue';
    import Annotation from '@/components/Annotation.vue';
    import { baseScene, controls, annotationHelpers, model } from './viewer-components';
    import { IAnnotation, IEnvironmentOption } from '@/types';
    import { ArButton, IS_AR_QUICKLOOK_CANDIDATE, IS_SCENEVIEWER_CANDIDATE } from '@poplar-studio/ar-button';
    import QrModal from '@/components/QrModal.vue';
    import SvgSheet from '@/components/SvgSheet.vue';
    import { emitter, Events, waitForEvent, emitGlobal } from '@/hooks/useEmitter';
    import SideBar from '@/components/SideBar.vue';
    import Settings from '@/components/Settings.vue';

    /**
     * Component is designed to be self encapsulated so it can be lifted out and placed into design system in due course
     */
    @Component({
        components: {
            FontAwesomeIcon,
            Annotation,
            BCol,
            BRow,
            QrModal,
            SvgSheet,
            SideBar,
            Settings,
        },
        filters: {
            formatDate(value: string) {
                return moment(value).format('D MMMM YYYY [at] hh:mm A');
            },
        },
    })
    export default class ModelViewer extends Vue {
        @Prop({ type: String }) readonly url!: string;
        @Prop({ type: Boolean, default: false }) readonly autoLoad!: boolean;
        @Prop({ type: Boolean, default: false }) readonly enableAnnotations!: boolean;
        @Prop({ type: Array }) annotations!: IAnnotation[];
        @Prop({ type: Array }) environments!: IEnvironmentOption[];
        @Prop({ type: Boolean, default: false }) transparentBg!: boolean;
        @Prop({ type: Array }) initialRotation!: [number, number];
        @Prop({ type: Boolean }) canEditAnnotations!: boolean;
        @Prop({ type: Boolean, default: false }) autoOpenAr!: boolean;
        @Prop({ type: String }) usdzUrl!: string;
        @Prop({ type: Boolean, default: true }) enableAr!: boolean;
        @Prop({ type: Boolean, default: false }) disableShadows!: boolean;

        @Watch('annotations.length')
        onAnnotationsChange(newVal: number, oldVal: number) {
            if (newVal > oldVal) {
                this.creatingNewAnnotation = false;
            }

            this.largestDisplayNum = this.annotations.reduce((acc, annotation) => {
                if (annotation.displayNum && annotation.displayNum > acc) {
                    return annotation.displayNum;
                }

                return acc;
            }, 0);

            if (this.largestDisplayNum === 0) {
                this.largestDisplayNum = this.annotations.length;
            }

            baseScene.render();
        }

        playIcon = faPlay;
        pauseIcon = faPause;
        xIcon = faTimes;

        showSettings: boolean = false;
        showInfo: boolean = false;
        autoRotate: boolean = false;
        lightIntensity: number = 1;
        hasAnimations: boolean = false;
        isAnimating: boolean = false;
        animationTime: number = 0;
        animationLength: number = 7;
        isFullScreen: boolean = false;
        resetButtonActive: boolean = false;
        showAnnotations: boolean = false;
        lastTouchStart!: TouchEvent;
        showInstructions: boolean = false;
        showHint: boolean = false;
        showQrModal = false;
        qrUrl = '';
        arError = false;
        resizeObserver: ResizeObserver | null = null;
        info = {
            boundings: {
                dimensions_cm: {},
                dimensions_m: {},
                center: {},
            },
            fileSize: '',
            vertexCount: 0,
        };

        newAnnotation = {
            text: '',
            position: { x: 0, y: 0, z: 0 },
        };

        largestDisplayNum = 0;
        creatingNewAnnotation: boolean = false;

        @Ref('canvasContainer') canvasContainer!: HTMLDivElement;
        @Ref('viewerContainer') viewerContainer!: HTMLDivElement;

        mounted() {
            // If USDZ available launch into ar right away otherwise have to wait for glb to have loaded
            if (this.autoOpenAr && this.usdzUrl) {
                this.openAR();
            }

            if (this.autoLoad) {
                this.init();
            }
        }

        beforeDestroy() {
            if (this.resizeObserver) {
                this.resizeObserver.unobserve(this.canvasContainer);
            }
        }

        async openAR() {
            if (IS_AR_QUICKLOOK_CANDIDATE && window.location !== window.parent.location) {
                // If iOS and in iframe launch new window as QuickLook does not work in iframes
                window.open(this.generateArUrl(), '_blank');
            } else if (IS_AR_QUICKLOOK_CANDIDATE || IS_SCENEVIEWER_CANDIDATE) {
                let usdz = this.usdzUrl;

                // If no USDZ url provided generate USDZ file from three.js scene
                if (!usdz && IS_AR_QUICKLOOK_CANDIDATE) {
                    try {
                        usdz = await model.exportToUSDZ();
                    } catch (e) {
                        this.showArError();
                    }
                }

                const button = new ArButton({
                    gltf: this.url,
                    usdz,
                    scale: 'fixed',
                });

                button.onReject(() => {
                    this.showArError();
                });

                button.openAr();
            } else if (this.showQrModal) {
                this.showQrModal = false;
            } else {
                this.qrUrl = this.generateArUrl();
                this.showQrModal = true;
            }
        }

        generateArUrl() {
            let url = `https://${
                process.env.VUE_APP_ENV === 'production' ? '' : 'staging.'
            }viewer.poplar.studio?url=${encodeURIComponent(this.url)}&ar=true`;

            if (this.usdzUrl) {
                url += `&usdz=${encodeURIComponent(this.usdzUrl)}`;
            }

            return url;
        }

        handleCloseQrModal() {
            this.showQrModal = false;
        }

        showArError() {
            this.arError = true;

            setTimeout(() => {
                this.arError = false;
            }, 4000);
        }

        init(): void {
            baseScene.camera.position.setZ(1);

            baseScene.mountCanvas(this.canvasContainer);

            this.resizeObserver = new ResizeObserver(([entry]) => {
                let height, width;

                if (entry.borderBoxSize) {
                    width = entry.borderBoxSize[0].inlineSize;
                    height = entry.borderBoxSize[0].blockSize;
                } else {
                    width = entry.contentRect.width;
                    height = entry.contentRect.height;
                }

                baseScene.resizeCanvas(width, height);
            });
            this.resizeObserver.observe(this.canvasContainer);

            emitter.emit(Events.VIEWER_LAUNCHED);

            const p1 = model.load(this.url).then(() => {
                baseScene.scene.add(model);

                this.hasAnimations = model.getAnimationList().length > 0;

                centerObject(model);
                zoomCameraToFitObject(baseScene.camera, model, controls);

                controls.saveState();

                if (this.hasAnimations) {
                    this.animationLength = model.animationClips[0].duration;
                }

                if (this.initialRotation) {
                    controls.setControlsRotation(...this.initialRotation);
                }

                controls.setControlsRotation(1.3, controls.getAzimuthalAngle(), true);

                emitGlobal(Events.MODEL_LOADED);
                this.info = model.getInfo();
            });

            const p2 = waitForEvent(Events.ENVIRONMENTS_LOADED);

            Promise.all([p1, p2])
                .then(() => {
                    baseScene.render();

                    if (this.autoOpenAr && !this.usdzUrl) {
                        this.openAR();
                    }

                    this.$emit('loaded');
                })
                .catch((err) => {
                    console.log(err);
                    this.$emit('load-error');
                });

            baseScene.onRender(() => {
                if (model.isAnimating) {
                    this.animationTime = model.currentAction.time;
                }
            });

            /**
             * Needed for the preview queue renderings in the CMS
             * Function is attached to the window object so it can then be accessed by puppeteer in the CMS worker
             */
            const takeScreenshot = () => {
                // @ts-ignore
                baseScene._render();

                return baseScene.renderer.domElement.toDataURL();
            };

            // @ts-ignore
            window.takeScreenshot = takeScreenshot.bind(this);
        }

        resizeCanvasToContainer() {
            const rect = this.canvasContainer.getBoundingClientRect();
            baseScene.resizeCanvas(rect.width, rect.height);
        }

        toggleFullScreen() {
            if (this.isFullScreen) {
                document.exitFullscreen();
            } else {
                this.viewerContainer.requestFullscreen();
            }

            this.isFullScreen = !this.isFullScreen;
        }

        get intensity(): number {
            return this.lightIntensity;
        }

        set intensity(value: number) {
            this.lightIntensity = value;
            baseScene.renderer.toneMappingExposure = value;
            baseScene.render();
        }

        toggleAutoRotate() {
            this.autoRotate = !this.autoRotate;

            controls.setAutoRotate(this.autoRotate);
        }

        reset() {
            controls.reset();
        }

        handleScrubBarChange(e: InputEvent) {
            const target = e.target as HTMLInputElement;
            const time = Number(target.value);

            model.setAnimationTime(time);
            this.isAnimating = false;
            this.animationTime = time;
        }

        toggleAnimation() {
            if (model.isAnimating) {
                model.pauseAnimation();
            } else {
                model.playAnimation();
                this.showAnnotations = false;
                this.showHint = false;
            }

            this.isAnimating = model.isAnimating;
        }

        toggleSettings() {
            this.showSettings = !this.showSettings;
        }

        toggleInfo() {
            this.showInfo = !this.showInfo;
        }

        toggleInstructions() {
            this.showInstructions = !this.showInstructions;
        }

        toggleShowAnnotations() {
            this.showAnnotations = !this.showAnnotations;
            this.showHint = this.showAnnotations;

            if (this.showAnnotations) {
                model.setAnimationTime(0);
                this.animationTime = 0;
            }
            model.setAnnotationVisibility(this.showAnnotations);
        }

        toggleShowHint() {
            this.showHint = !this.showHint;
        }

        handleCanvasTouchStart(e: TouchEvent) {
            this.lastTouchStart = e;
        }

        /**
         * Mobile devices do not have a double click event to hook into so we have to make our own
         * The following function will record the timestamp of every touchend event and if another touchend event
         * happens within 300ms it will count it as a double click
         */
        lastTapTime: number = 0;
        timeout!: any;
        handleCanvasTouchEnd() {
            if (this.showAnnotations) {
                const currentTime = new Date().getTime();
                const tapLength = currentTime - this.lastTapTime;
                clearTimeout(this.timeout);

                if (tapLength < 300 && tapLength > 0) {
                    const [x, y] = this.getLastTouchOffset();

                    this.createNewAnnotationIfIntercept(x, y);
                } else {
                    this.timeout = setTimeout(() => {
                        this.getLastTouchOffset();
                        clearTimeout(this.timeout);
                    }, 300);
                }
                this.lastTapTime = currentTime;
            }
        }

        getLastTouchOffset(): Array<number> {
            const canvasContainer = this.$refs.canvasContainer as HTMLElement;
            const rect = canvasContainer.getBoundingClientRect();
            const x = this.lastTouchStart.touches[0].clientX - rect.left;
            const y = this.lastTouchStart.touches[0].clientY - rect.top;

            return [x, y];
        }

        handleCanvasDoubleClick(e: MouseEvent) {
            if (this.canEditAnnotations && this.showAnnotations) {
                this.createNewAnnotationIfIntercept(e.offsetX, e.offsetY);
            }
        }

        createNewAnnotationIfIntercept(x: number, y: number) {
            const intercept = annotationHelpers.getModelIntercept(new THREE.Vector2(x, y), model);

            if (intercept) {
                this.creatingNewAnnotation = true;
                const localPos = model.worldToLocal(intercept);
                this.newAnnotation = {
                    text: '',
                    position: {
                        x: localPos.x,
                        y: localPos.y,
                        z: localPos.z,
                    },
                };
            }
        }

        handleCreateAnnotation(data: IAnnotation) {
            this.$emit('annotation-created', {
                ...data,
                displayNum: this.largestDisplayNum + 1,
            });
        }

        handleCancelCreateAnnotation() {
            this.creatingNewAnnotation = false;
        }

        handleSaveAnnotation(data: IAnnotation) {
            this.$emit('annotation-saved', data);
        }

        handleDeleteAnnotation(data: IAnnotation) {
            this.$emit('annotation-deleted', data);
        }

        get isMobile(): boolean {
            // @ts-ignore
            return this.$mq === 'xs';
        }

        get canFullscreen(): boolean {
            return !!document.body.requestFullscreen;
        }
    }
